export default {
  home: {
    title: 'IAV Simulation Resource & Tasks Management Platform',
    message:
      'Integrated Management of Simulation Resources and Tasks, Simplifying the Simulation Process and Improving Work Efficiency',
    message2: 'They are using IAV Platform.',
  },

  login: {
    welcome1: 'Hello',
    welcome2: 'Welcome to IAV Scenario Simulation Platform',
  },

  resource: {
    scenario_file: {
      file_name:'File Name',
      bucket_name: 'Software',
      bucket_version: 'Software Version',
      test_method: 'Test Method',
      content_format: 'Content Format',
      if_linked_scenario: 'Link to Scenario',
      linked_scenario: 'Linked Scenario',
      custom_metadata: 'Custom Metadata',
      upload: 'Upload File',
      description: 'Description',
      author: 'Author',
      project: 'Project Class',
    },
  },

  system: {
    title: 'System Setting',
    appearance_access: {
      title: 'Appearance & Accessibility',
      theme: {
        title: 'Theme',
        placeholder: 'Select the Theme',
        light: 'Light Mode',
        dark: 'Dark Mode',
      },
      Language: {
        title: 'Language',
        placeholder: 'Select the Language',
        eng: 'English',
        zhcn: 'Simple Chinese',
      },
      fontsize: {
        title: 'Font Size',
        placeholder: 'Select the Font Size',
        large: 'Large',
        default: 'Default',
        small: 'Small',
      },
    },
    download: {
      title: 'Download',
      location: {
        title: 'Location',
        placeholder: 'Select the Download Path',
        tip: 'Always ask where to save downloaded file',
      },
    },
    simulation_software: {
      title: 'Simulation Software',
      version: 'Version',
      version_placeholder: 'Input the Version of Software',
      installpath: 'Installation Path',
      installpath_placeholder: 'Input the installation path',
      projectpath: 'Project Path',
      projectpath_placeholder: 'Input the project path',
      VTD: 'VTD',
      Carla: 'Carla',
      OpenX: 'OpenX',
      CarSim: 'CarSim',
      CarMaker: 'CarMaker',
      Aurelion: 'Aurelion',
    },
    change: 'change',
    feedback: {
      title: 'FeedBack',
      placeholder:
        'Please assist in filling out the prompt information and problem description, which will help us identify and resolve issues more rapidly.',
      input_title: 'File Upload',
      input_placeholder: 'doc, ppt, jpg...',
    },
    dialog: {},
  },

  tabbar: {
    setting: {
      title: 'System Setting',
      theme: 'Theme Setting',
      color: 'Color Setting',
      lang: 'Language Setting',
      font: 'Font Setting',
    },
  },

  route: {
    login: 'login',
    welcome: 'welcome',
    home: 'Home',
    dashboard: 'Dashboard',
    resource_center: 'Resource Center',
    scenario_overview: 'Scenario Overview',
    scenario_detail: 'Scenario Detail',
    video_preview: 'Video Preview',
    intro: 'INTRO',
    detail_upload: 'UPLOAD',
    scenario_file: 'Scenario File',
    scenario_file_detail: 'File Detail',
    param_kpi: 'Parameter & KPI',
    vehicle: 'Vehicle',
    ConfigureVehicle: 'Vehicle Configuration',
    vehicle_info: 'Vehicle Infomation',
    suts: 'SUTs',
    valdivia_config: 'Valdivia Config',
    llm: 'LLM',
    task_center: 'Task Center',
    task_management: 'Task Management',
    task_detail: 'Task Detail',
    task_create: 'Task Create',
    system_center: 'System Center',
    user_center: 'User Center',
    user_guide: 'User Guide',
    system_setting: 'System Setting',
    permission_setting: 'Permission Setting',
    feedback: 'Feedback',
    About: 'About',
  },
}
