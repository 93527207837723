export default {
  home: {
    title: 'IAV仿真资源与任务管理平台',
    message: '仿真资源和任务的集成管理，简化仿真过程，提高工作效率',
    message2: '他们正在使用IAV平台。',
  },

  login: {
    welcome1: '你好',
    welcome2: '欢迎来到IAV场景仿真平台',
  },

  resource: {
    scenario_file: {
      file_name:'文件名称',
      bucket_name: '软件',
      bucket_version: '软件版本',
      test_method: '测试方法',
      content_format: '场景格式',
      if_linked_scenario: '是否要链接到场景?',
      linked_scenario: '已链接的场景',
      custom_metadata: '自定义元数据',
      upload: '上传文件',
      description: '文件描述',
      author: '文件作者',
      project: '所属项目',
    },
  },

  system: {
    title: '系统设置',
    appearance_access: {
      title: '外观和可访问性',
      theme: {
        title: '主题',
        placeholder: '请选择主题',
        light: '浅色模式',
        dark: '深色模式',
      },
      Language: {
        title: '语言',
        placeholder: '请选择语言',
        eng: '英语',
        zhcn: '简体中文',
      },
      fontsize: {
        title: '字体大小',
        placeholder: '请选择字体大小',
        large: '大',
        default: '默认',
        small: '小',
      },
    },
    download: {
      title: '下载',
      location: {
        title: '位置',
        placeholder: '请选择下载路径',
        tip: '总是询问我在哪个位置保存下载文件',
      },
    },
    simulation_software: {
      title: '仿真软件',
      version: '版本',
      version_placeholder: '请输入软件版本',
      installpath: '安装路径',
      installpath_placeholder: '请输入安装路径',
      projectpath: '项目路径',
      projectpath_placeholder: '请输入项目路径',
      VTD: 'VTD',
      Carla: 'Carla',
      OpenX: 'OpenX',
      CarSim: 'CarSim',
      CarMaker: 'CarMaker',
      Aurelion: 'Aurelion',
    },
    change: '更改',
    feedback: {
      title: '反馈内容',
      placeholder:
        '请协助填写提示信息和问题描述，这将有助于我们更快地识别和解决问题。',
      input_title: '上传文件',
      input_placeholder: 'doc, ppt, jpg...',
    },
  },

  tabbar: {
    setting: {
      title: '系统设置',
      theme: '主题设置',
      color: '色彩设置',
      lang: '语言设置',
      font: '字体设置',
    },
  },

  route: {
    login: '登录',
    welcome: '欢迎',
    home: '首页',
    dashboard: '仪表盘',
    resource_center: '资源中心',
    scenario_overview: '场景概览',
    scenario_detail: '场景细节',
    video_preview: '视频预览',
    intro: '简介',
    detail_upload: '上传',
    scenario_file: '场景文件',
    scenario_file_detail: '详细内容',
    param_kpi: '参数和KPI',
    vehicle: '车辆',
    ConfigureVehicle: '车辆配置',
    vehicle_info: '车辆信息',
    suts: 'SUTs',
    valdivia_config: 'Valdivia配置',
    llm: 'AI OpenScenario生成工具',
    task_center: '任务中心',
    task_management: '任务管理',
    task_detail: '任务细节',
    task_create: '生成任务',
    system_center: '系统中心',
    user_center: '用户中心',
    user_guide: '用户指南',
    system_setting: '系统设置',
    permission_setting: '权限设置',
    feedback: '意见反馈',
    About: '关于',
  },
}
